export function orgParenting() {
  let arPro = [
    { orgId: 0, name: "Maryland State", state: 'General' },
    { orgId: 0, name: "North Carolina State", state: 'General' },
    // { orgId: 118, name: 'CoreLife Master', state: 'Maryland' },
    { orgId: 122, name: 'CL Bel Air', state: 'Maryland' },
    { orgId: 123, name: 'CL Bowie', state: 'Maryland' },
    { orgId: 124, name: 'CL Easton', state: 'Maryland' },
    { orgId: 125, name: 'CL Frederick', state: 'Maryland' },
    { orgId: 126, name: 'CL Germantown', state: 'Maryland' },
    { orgId: 127, name: 'CL Hagerstown', state: 'Maryland' },
    { orgId: 128, name: 'CL Hunt Valley', state: 'Maryland' },
    { orgId: 129, name: 'CL Odenton', state: 'Maryland' },
    { orgId: 130, name: 'CL Owings Mills', state: 'Maryland' },
    { orgId: 131, name: 'CL Pasadena', state: 'Maryland' },
    { orgId: 132, name: 'CL Prince Frederick', state: 'Maryland' },
    { orgId: 133, name: 'CL Salisbury', state: 'Maryland' },
    { orgId: 134, name: 'CL Severna Park', state: 'Maryland' },
    { orgId: 135, name: 'CL Silver Spring', state: 'Maryland' },
    { orgId: 136, name: 'CL Westminster', state: 'Maryland' },
    { orgId: 137, name: 'CL Waldorf', state: 'Maryland' },
    { orgId: 138, name: 'CL White Marsh', state: 'Maryland' },
    { orgId: 149, name: 'CL Corporate', state: 'Maryland' },
    { orgId: 139, name: 'CL Village Point', state: 'North Carolina' },
    { orgId: 140, name: 'CL Pineville', state: 'North Carolina' },
    { orgId: 141, name: 'CL Ballantyne', state: 'North Carolina' },
    { orgId: 142, name: 'CL Greensboro', state: 'North Carolina' },
    { orgId: 143, name: 'CL Huntersville', state: 'North Carolina' },
    { orgId: 144, name: 'CL Kernersville', state: 'North Carolina' },
    { orgId: 145, name: 'CL Ardmore', state: 'North Carolina' },
    { orgId: 146, name: 'CL Highland Oaks', state: 'North Carolina' },
    { orgId: 147, name: 'CL Thomasville', state: 'North Carolina' },
    { orgId: 148, name: 'CL Salisbury_NC', state: 'North Carolina' },
    { orgId: 161, name: 'Matthews, NC', state: 'North Carolina' },
    { orgId: 176, name: 'CL Lexington Park, MD', state: 'Maryland' },
  ]

  let arDev = [
    { orgId: 1, name: "Maryland State", state: 'General' },
    { orgId: 1, name: "North Carolina State", state: 'General' },
    { orgId: 1, name: "Parent Org", state: 'Maryland' },
    { orgId: 1, name: "Child Org A", state: 'Maryland' },
    { orgId: 1, name: "Child Org B", state: 'Maryland' },
    { orgId: 1, name: "Child Org Theta", state: 'Maryland' },
    { orgId: 1, name: "Child Org Zeta", state: 'Maryland' },
    { orgId: 1, name: "Child Org Beta", state: 'Maryland' },
    { orgId: 1, name: "Child Org Epsilon", state: 'Maryland' },
    { orgId: 1, name: "Child Org Gamma", state: 'North Carolina' },
    { orgId: 1, name: "Child Org Kappa", state: 'North Carolina' },
    { orgId: 1, name: "Child Org Tau", state: 'North Carolina' },
  ];

  // console.log(oRGS);
  let use = process.env.REACT_APP_ENV === 'STAGGING' || process.env.REACT_APP_ENV === 'PROD' ? arPro : arDev;
  let res = [];
  for (let i = 0; i < use.length; i++) {
    const el = use[i];
    res.push(
      {
        cat: el.state,
        key: el.name
      });
  }
  return res;
}

export const uuidv4 = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function convertUTCDateToLocalDate(date: any) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}

export function getOMReportNames() {
  return [{
    title: 'ABN Needed',
    value: '/corelife/om-abnneeded'
  },{
    title: 'Active Without Next Follow-Up',
    value: '/corelife/om-activewithoutnfv'
  },{
    title: 'Behavioral Health',
    value: '/corelife/om-bhstatus'
  },{
    title: 'Follow-Up Daily Audit',
    value: '/corelife/om-dailyaudit'
  },{
    title: 'Follow Up In Past',
    value: '/corelife/om-past'
  },{
    title: 'Incomplete Movement Consult',
    value: '/corelife/om-incompletemovement'
  },{
    title: 'Missing BH Paperwork',
    value: '/corelife/om-missingbhpaperwork'
  },{
    title: 'NPA Before Today (Leads)',
    value: '/corelife/om-npabeforetoday'
  },{
    title: 'NPA Confirmation Calls',
    value: '/corelife/om-confirmation'
  },{
    title: 'NPA to be Faxed',
    value: '/corelife/om-npafaxed'
  },{
    title: 'PA Required',
    value: '/corelife/om-parequired'
  },{
    title: 'Restart Report',
    value: '/corelife/om-restart'
  },{
    title: 'Unable to Verify Insurance (Leads)',
    value: '/corelife/om-unabletoverify'
  },{
    title: 'Unable to Verify Insurance (Managed)',
    value: '/corelife/om-unblverifymanagedins'
  },{
    title: 'Volume Report',
    value: '/corelife/om-volume'
  }];
}

export function getSelectedOMReport(currentView: string) {
  switch (currentView) {
    case 'om-dailyaudit':
      return{
        title: 'Follow-Up Daily Audit',
        value: '/corelife/om-dailyaudit'
      }
    case 'om-confirmation':
      return{
        title: 'NPA Confirmation Calls',
        value: '/corelife/om-confirmation'
      }
    case 'om-past':
      return{
        title: 'Follow Up In Past',
        value: '/corelife/om-past'
      }
    case 'om-volume':
      return{
        title: 'Volume Report',
        value: '/corelife/om-volume'
      }
    case 'om-restart':
      return{
        title: 'Restart Report',
        value: '/corelife/om-restart'
      }
      case 'om-incompletemovement':
      return{
        title: 'Incomplete Movement Consult',
        value: '/corelife/om-incompletemovement'
      }
    case 'om-abnneeded':
      return{
        title: 'ABN Needed',
        value: '/corelife/om-abnneeded'
      }
    case 'om-activewithoutnfv':
      return{
        title: 'Active Without Next Follow-Up',
        value: '/corelife/om-activewithoutnfv'
      }
    case 'om-npafaxed':
      return{
        title: 'NPA to be Faxed',
        value: '/corelife/om-npafaxed'
      }
    case 'om-npabeforetoday':
      return{
        title: 'NPA Before Today (Leads)',
        value: '/corelife/om-npabeforetoday'
      }
    case 'om-unabletoverify':
      return{
        title: 'Unable to Verify Insurance (Leads)',
        value: '/corelife/om-unabletoverify'
      }
    case 'om-bhstatus':
      return{
        title: 'Behavioral Health',
        value: '/corelife/om-bhstatus'
      }
    case 'om-missingbhpaperwork':
      return{
        title: 'Missing BH Paperwork',
        value: '/corelife/om-missingbhpaperwork'
      }
    case 'om-unblverifymanagedins':
      return{
        title: 'Unable to Verify Insurance (Managed)',
        value: '/corelife/om-unabletoverifymanaged'
      }
    case 'om-parequired':
      return{
        title: 'PA Required',
        value: '/corelife/om-parequired'
      }
    default:
      break;
  }
}

export function isOMReport(currentView: string) {
  return ['om-dailyaudit', 'om-confirmation', 'om-past', 'om-volume', 'om-restart', 'om-incompletemovement', 'om-abnneeded', 'om-activewithoutnfv', 'om-npafaxed', 'om-unabletoverify', 'om-npabeforetoday', 'om-bhstatus', 'om-missingbhpaperwork', 'om-unblverifymanagedins', 'om-parequired'].includes(currentView) ? true : false;
}

export function log(...optionalParams: any[]) {
  if (process.env.REACT_APP_ENV !== 'PROD')
    console.log(...optionalParams);
}